import React from 'react';
import { Link } from 'react-router-dom';

class MediaBadges extends React.Component {
    render() {
        return (
            <div className="flexRow mediaBadges">
                <div className="flexCol">
                    <a href="https://www.youtube.com/channel/UCc7nwUzdbTKurTjjyf73U_g" target="_blank">
                        <div className="badgeButton">
                            <div><img className="badge" src="res/icons/youtube.svg" title="Youtube"></img></div>
                            <div>Youtube</div>
                        </div>
                    </a>
                </div>
                <div className="flexCol">
                    <a href="https://www.instagram.com/gamesbyfishlicka/" target="_blank">
                        <div className="badgeButton">
                            <div><img className="badge" src="res/icons/instagram.svg" title="Instagram"></img></div>
                            <div>Instagram</div>
                        </div>
                    </a>
                </div>
                <div className="flexCol">
                    <a href="https://twitter.com/FishlickaGames" target="_blank">
                        <div className="badgeButton">
                            <div><img className="badge" src="res/icons/twitter.svg" title="Twitter"></img></div>
                            <div>Twitter</div>
                        </div>
                    </a>
                </div>
                <div className="flexCol">
                    <a href="https://www.reddit.com/r/juicegalaxy/" target="_blank">
                        <div className="badgeButton">
                            <div><img className="badge" src="res/icons/reddit.svg" title="Reddit"></img></div>
                            <div>Reddit</div>
                        </div>
                    </a>
                </div>
                <div className="flexCol">
                    <a href="https://fishlicka.itch.io/juice-galaxy" target="_blank">
                        <div className="badgeButton">
                            <div><img className="badge" src="res/icons/itch.svg" title="Itch.io"></img></div>
                            <div>Itch.io</div>
                        </div>
                    </a>
                </div>
                <div className="flexCol">
                    <a href="https://store.steampowered.com/app/1708150/Juice_Galaxy/" target="_blank">
                        <div className="badgeButton">
                            <div><img className="badge" src="res/icons/steam.svg" title="Steam"></img></div>
                            <div>Steam</div>
                        </div>
                    </a>
                </div>

                {/* <div className="flexCol">
                    <a href="https://www.youtube.com/channel/UCc7nwUzdbTKurTjjyf73U_g" target="_blank">
                        <img className="badge" src="res/icons/tiktok.svg" width="100%" title="TikTok"></img></a>
                </div> */}

            </div>
          );
    }
}

export default MediaBadges;