import React from 'react';
import { Routes, Route, Link} from "react-router-dom";

import Home from './games/Home';
import JuiceGalaxy from './games/JuiceGalaxy';
import Swordface from './games/Swordface';
import OldFlash from './games/OldFlash';
import NotFound from '../NotFound';

class Games extends React.Component {
    render() {
        return (
            <div>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home />} />
                        <Route path="/juice-galaxy" element={<JuiceGalaxy />} />
                        <Route path="/swordface" element={<Swordface />} />
                        <Route path="/old-flash" element={<OldFlash />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </div>
          );
    }
}

export default Games;