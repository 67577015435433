import React from 'react';

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

class Contact extends React.Component {
    render() {
        return (
            <div style={{textAlign:'center'}}>
                <h2>Contact Me</h2>

                <p>Just send me an email at <a href="mailto:jackson@j4d3d.com">jackson@j4d3d.com</a></p>
            </div>
          );
    }

    componentDidMount() {
        
    }
}

export default Contact;