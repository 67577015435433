import React from 'react';
import { Routes, Route, Link} from "react-router-dom";

import Buddy from './body-building-buddy/Buddy';

class Apps extends React.Component {
    render() {
        return (
            <div>
                <Buddy/>
                <br/>
                <h2>Disclaimer</h2>
                <p>You should understand that when participating in any exercise or exercise program, there is the possibility of physical injury. If you engage with this program, you agree that you do so at your own risk, are voluntarily participating in these activities, assume all risk of injury to yourself, and agree to release and discharge the developer from any and all claims or causes of action, known or unknown, arising out of the Body Building Buddy app.</p>
                
                <h2>How To Use</h2>
                <p>Select some starter exercises and hit start. Turn off mute and the app will ring a bell every 15 minutes, giving you a random exercise to complete. By default, the app assumes you complete the exercise and logs it in a table on the page. If you want a different exercise, you can hit 'Pass' and then 'Fast Forward' to get a new one without logging the current one.</p>
                <p>Another way to use it is to repeately hit fast forward, doing each exercise as it is prompted. In this way you can go as fast or slow as you want, being careful not to over-exert yourself. This is more of a circuit type exercise and should amount to a good cardio workout.</p>
                <p>You may add or remove exercises as you choose, and save them to a cookie for the next time you use the app. There is also a settings panel with controls for exercise interval and intensity.</p>
            </div>
          );
    }
}

export default Apps;
