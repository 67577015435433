import React from 'react';
import { Routes, Route, Link} from "react-router-dom";

import Home from './other/Home';
import AboutThisSite from './other/articles/AboutThisSite';
import StockPriceHeatmaps from './other/articles/StockPriceHeatmaps';
import NotFound from '../NotFound';

class Other extends React.Component {
    render() {
        return (
            <div>
                {/* <Routes>
                    <Route exact path="/other" element={<Home />} />
                    <Route exact path="/other/articles/about" element={<AboutThisSite />} />
                    <Route exact path="/other/articles/stock-price-heatmaps" element={<StockPriceHeatmaps />} />
                    <Route element={<NotFound />} />
                </Routes> */}

                <Routes>
                    <Route path="/">
                        <Route index element={<Home />} />
                        <Route path="/about" element={<AboutThisSite />} />
                        <Route path="/stock-price-heatmaps" element={<StockPriceHeatmaps />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </div>
          );
    }

    componentDidMount() {
        
    }
}

export default Other;