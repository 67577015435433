import React from 'react';
import { Routes, Route, Link} from "react-router-dom";

import Home from './old-flash/Home';
import ShaveTheWorld from './old-flash/ShaveTheWorld';
import FloppyFrank from './old-flash/FloppyFrank';
import PurplePeopleEater from './old-flash/PurplePeopleEater';
import NotFound from '../../NotFound';

class OldFlash extends React.Component {
    render() {
        return (
            <div>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home />} />
                        <Route path="/shave-the-world" element={<ShaveTheWorld />}></Route>
                        <Route path="/floppy-frank" element={<FloppyFrank />}></Route>
                        <Route path="/purple-people-eater" element={<PurplePeopleEater />}></Route>
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </div>
          );
    }
}

export default OldFlash;