import React from 'react';
import MediaBadges from './MediaBadges';
import { Link } from 'react-router-dom';

function Footer() {
  let d = new Date();
  return (
    <footer>
        {/* <Link to="/donate" style={{width:'100%'}}> */}
          <MediaBadges/>
          <p className="unselectable" style={{height:"1em"}}>
            {"Copyright "+d.getFullYear()+" J4D3D LLC, All Rights Reserved"}
          </p>
        {/* </Link> */}
    </footer>
  );
}

export default Footer;
