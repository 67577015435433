import React from 'react';
import { Link } from 'react-router-dom';

class PrivacyPolicy extends React.Component {
    render() {
        return (
            <div>
                <h2>Video Time Warp</h2>
                <h3>Privacy Policy</h3>
                <p>This privacy policy governs your use of the software application Video Time Warp for mobile devices, which was created by Jackson Rain.</p>
                <p>The app does not collect any personal information. External storage permissions are requested soley for the purpose of generating time-displaced videos, which remain under the user's full control.</p>
                <p>This privacy policy may be updated from time to time for any reason.</p>
                <p>Questions or suggestions about the privacy policy? Contact <a href="mailto:jackson@j4d3d.com">jackson@j4d3d.com</a>.</p>
            </div>
          );
    }
}

export default PrivacyPolicy;