import React from 'react';

import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav>

      <div className="dropdown">
        <div className="drop-button">
          <Link to="/games">Games</Link>
          <div className="drop-content">
            <Link to="/games/juice-galaxy"><div className="drop-element">Juice Galaxy</div></Link>
            {/* <a href="https://fishlicka.itch.io/juice-galaxy" target="_blank" rel="noopener noreferrer"><div className="drop-element">Juice Galaxy</div></a> */}
            <a href="https://fishlicka.itch.io/schwing" target="_blank" rel="noopener noreferrer"><div className="drop-element">Schwing</div></a>
            <Link to="/games/swordface"><div className="drop-element">Swordface</div></Link>
            <Link to="/games/old-flash"><div className="drop-element">Old Flash Games</div></Link>
          </div>
        </div>
      </div>

      <div className="dropdown">
        <div className="drop-button">
          <Link to="/apps">Apps</Link>
          <div className="drop-content">
            <Link to="/apps/body-building-buddy"><div className="drop-element">Body Building Buddy</div></Link>
            <Link to="/apps/video-time-warp"><div className="drop-element">Video Time Warp</div></Link>
            <Link to="/apps/crazy-goggles"><div className="drop-element">Crazy Goggles</div></Link>
          </div>
        </div>
      </div>

      <div className="dropdown">
        <div className="drop-button">
          <Link to="/code">Code</Link>
        </div>
      </div>

      <div className="dropdown">
        <div className="drop-button">
          <Link to="/other">Other</Link>
          <div className="drop-content">
            <Link to="/other/about"><div className="drop-element">About</div></Link>
            <Link to="/contact"><div className="drop-element">Contact Me</div></Link>
            {/* <a href="mailto:jackson@j4d3d.com"><div className="drop-element">Contact Me</div></a> */}
          </div>
        </div>
      </div>

    </nav>
  );
}

export default Navbar;
