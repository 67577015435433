import React from 'react';

import { Routes, Route, Link} from "react-router-dom";

class Home extends React.Component {
    render() {
        return (
            <div>
                <h2>Video Time Warp</h2>
                <strong>Video Time Warp</strong> is an Android App that enables users to create trippy time-displaced (aka slit-scan) videos using just their phones.
                <p>The app is available for free on <a href="https://play.google.com/store/apps/details?id=com.olioo.vtw&hl=en_US">Google Play</a>.</p>
                <ul>
                    <li><Link to="/apps/video-time-warp/help">User Guide</Link></li>
                    <li><Link to="/apps/video-time-warp/privacy-policy">Privacy Policy</Link></li>
                </ul>
                <p>Please <a href="mailto:jackson@j4d3d.com">Contact Me</a> if you make any cool videos.</p>
                <p>Video Time Warp's privacy policy can be found <Link to="/apps/video-time-warp/privacy-policy">here</Link>.</p>
                <div className="spacer10M32"></div>
                <div style={{textAlign:'center'}}>
                    <p>There are some examples below. More videos can be found in <a href="https://www.youtube.com/playlist?list=PLXJU3OQuvPyWhEYv2yLQBE9HJI4is2Zce">this playlist</a>.</p>
                    <div className="video-container"><iframe title="asdf0" width="560" height="315" src="https://www.youtube.com/embed/4q1p4czu7EE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    <div className="video-container"><iframe title="asdf1" width="560" height="315" src="https://www.youtube.com/embed/gzCMkWrpSpQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    <div className="video-container"><iframe title="asdf2" width="560" height="315" src="https://www.youtube.com/embed/G5Bib33am9U" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                    <div className="video-container"><iframe title="asdf4" width="560" height="315" src="https://www.youtube.com/embed/NWxh1Ip2JOg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
                </div>
            </div>
          );
    }
}

export default Home;