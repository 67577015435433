import React from 'react';
import TwoColumn from '../../TwoColumn';

class Swordface extends React.Component {
    render() {
        return (
            <div>              
                <div>
                    <div style={{textAlign:'center'}}>
                        <div className="video-container"><iframe title="Juice Galaxy Trailer" width="560" height="315" src="https://www.youtube.com/embed/uvd6uBURhs8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{borderRadius:'8px'}}></iframe></div>
                        {/* <a href="https://store.steampowered.com/app/1708150/Juice_Galaxy/" target="_blank" rel="noopener noreferrer">
                        <img src="/res/Games/JuiceGalaxy/header capsule.png" style={{width:"100%"}}></img>
                        </a> */}
                        <div class="flexRow" style={{padding:'1em'}}>
                            <div class="flexCol"><a href="https://store.steampowered.com/app/1708150/Juice_Galaxy/">Play on Steam</a></div>
                            <div class="flexCol"><a href="https://fishlicka.itch.io/juice-galaxy">Play on Itch.io</a></div>
                        </div>
                        <h1><a href="https://store.steampowered.com/app/1708150/Juice_Galaxy/" target="_blank" rel="noopener noreferrer">Juice Galaxy</a></h1>
                    </div>
                    <p style={{marginTop:'2em'}}>Juice is everything. There is nothing in the Fog. There is no greater honor than to serve the Juice Queen, and the sky is definitely not made of plywood.</p>
                    <p>Juice Galaxy is a happy place to fly around and smack things and drink juice. And explore things. And just, flip around doing stuff. Use any of a multitude of weapons to clobber and devour the local wildlife. Gather juice to increase your power and take on more powerful enemies.</p>
                    <p>The game is still in active development. There's still a ton of work to do. I want to polish the physics and add a ton more abilities, places, music, items, monsters, and story.</p>
                </div>
                <div class="flexRow">
                    <div class="flexCol">
                        <ul>
                            <li><a href="https://store.steampowered.com/app/1708150/Juice_Galaxy/">Play on Steam</a></li>
                            <li><a href="https://fishlicka.itch.io/juice-galaxy">Play on Itch.io</a></li>
                        </ul>
                    </div>
                    <div class="flexCol">
                        <ul>
                            <li><a href="https://www.youtube.com/c/Fishlicka">Youtube</a></li>
                            <li><a href="https://juice-world.fandom.com/wiki/Juice_Galaxy">Wiki</a></li>
                        </ul>
                    </div>
                    <div class="flexCol">
                        <ul>
                            <li><a href="https://www.reddit.com/r/juicegalaxy">Subreddit</a></li>
                            <li><a href="https://www.instagram.com/gamesbyfishlicka/">Instagram</a></li>
                        </ul>
                    </div>
                    <div class="flexCol">
                        <ul>
                            <li><a href="https://fishlicka.itch.io/juice-galaxy/devlog">Devlogs (Itch.io)</a></li>
                            <li><a href="https://forums.tigsource.com/index.php?topic=69253">Devlogs (old, TIGSource)</a></li>
                        </ul>
                    </div>
                </div>
                <div class="flexRow" style={{width:'100%'}}>
                    <div class="flexCol" style={{margin:'.5em'}}><img src="/res/Games/JuiceGalaxy/screenshot_batty.png" style={{width:'100%', borderRadius:'8px'}}></img></div>
                    <div class="flexCol" style={{margin:'.5em'}}><img src="/res/Games/JuiceGalaxy/screenshot_clog selfie fixed.png" style={{width:'100%', borderRadius:'8px'}}></img></div>
                    <div class="flexCol" style={{margin:'.5em'}}><img src="/res/Games/JuiceGalaxy/screenshot_inventory 2.png" style={{width:'100%', borderRadius:'8px'}}></img></div>
                </div>
            </div>
          );
    }
}

export default Swordface;