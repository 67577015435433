import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from './Navbar';

let title = 'J4D3D';

class Header extends React.Component {
    render() {
        let html = [];
        let letters = title.split('');
        let colors = ['#f50', '#f80', '#ff0', '#8f0', '#0f0', '#8f0', '#ff0', '#f80'];//'#f0f', '#f00', '#0f0', '#0ff'];
        //html.push(<span style={{color:'#ddd'}} key={letters.length+''}>{"Muta"}</span>);
        for (let i=0; i<letters.length; i++)
            html.push(<span style={{color:colors[i%colors.length]}} key={i+''}>{letters[i]}</span>);
        html.push(<span style={{color:'#ddd'}} key={letters.length+''}>{" Software"}</span>);

        return (
            <header className="unselectable">
                {/* <div style={{textAlign:'right',position:'absolute',top:0,right:0}}>
                    <Link to="/contact">Contact</Link><br />
                    <Link to="/links">Links</Link>
                </div> */}
                <Link to="/" className='homeBtn'>
                    <h1>
                        {html}
                    </h1>
                </Link>
                <Navbar/>
                
            </header>
          );
    }
}

export default Header;
