import React from 'react';
import { Routes, Route, Link} from "react-router-dom";

class Home extends React.Component {
    render() {
        return (
            <div>
                <h2>Writing</h2>
                <ul>
                    <li><Link to="/other/about/">About This Website</Link></li>
                    <li><Link to="/other/stock-price-heatmaps/">Stock Price Time Series Heatmaps</Link></li>
                </ul>
                <br />
                <h2>Devlogs</h2>
                <ul>
                    <li><a href="https://fishlicka.itch.io/juice-galaxy/devlog">Juice Galaxy current (Itch.io)</a></li>
                    <li><a href="https://forums.tigsource.com/index.php?topic=69253">Juice Galaxy old (TIGSource)</a></li>
                    <li><a href="https://forums.tigsource.com/index.php?topic=66186.0">Swordface</a></li>
                </ul>
                <br />
                
                {/* <h2>Ideas</h2>
                <ul>
                    <li>Decentralized Collaborative Animation Studio Web App</li>
                    <li>Human Work Economy-Based MMO Game</li>
                </ul>
                <h2>Postmortems</h2>
                <ul>
                    <li>Crypto-Vis</li>
                </ul> */}
            </div>
          );
    }
}

export default Home;