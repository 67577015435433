import React from 'react';
import { Link } from 'react-router-dom';

class VideoTimeWarpHelp extends React.Component {
    render() {
        return (
            <div>
                <h2>How To Use</h2>

                <div>Tutorial video coming soon!</div>

                <h3>Basics</h3>
                <p>The <span className="highlight">Video Player</span> button lets you select a video to play in the background. Tap the background at any time to show/hide the menus.</p>
                <ol style={{lineHeight:"1.5em"}}>
                    <li>Hit <span className="highlight">Warp Video</span> to select a video to warp.</li>
                    <li>Adjust the settings (or dont) with the sliders and text boxes.</li>
                    <li>Hit <span className="highlight">Start Warping</span> to begin.</li>
                </ol>
                
                <p>The actual warping process can take a while, but you can minimize or even close the app and it will work in the background.</p>
                <p style={{lineHeight:"1.3em"}}>You can also <span className="highlight">Halt Warping</span> at any time and it will cut the video short where its at. Note that until the first batch has encoded, there is no video to cut short at all.</p>
                <h3>Quick Tips</h3>
                <ul>
                    <li>Using slow motion footage or footage of slow movement can produce a smoother warped video.</li>
                    <li>You can preview your settings by using a low framerate, which greatly reduces the time it takes to warp a video.</li>
                    <li>Videos where the camera does not move, or moves in a consistent way, tend to produce interesting warps.</li>
                    <li>You can minimize or even close the app while warping, but the odds of warping being cut off increase the longer the app isn't open.</li>
                </ul>
                <h3>Settings Glossary</h3>
                <ul>
                    <li><strong>Filename</strong>: The video will be saved as filename.mp4 in a folder named 'Time Warped'.</li>
                    <li><strong>Warp Mode</strong>: The shape of the gradient that will be used to distort the video along the time axis.</li>
                    <li><strong>Seconds</strong>: The strength of the warp effect, in seconds.</li>
                </ul>
                <h4>~ Advanced Settings ~</h4>
                <ul>
                    <li><strong>Invert Warp Mode</strong>: Flips the shape of the gradient.</li>
                    <li><strong>Framerate</strong>: The framerate of the output video, can be set to a low value to quickly warp a preview of current settings.</li>
                    <li><strong>Bitrate</strong>: The quality of the video. Should be alright by default but can be set manually using the textbox.</li>
                    <li><strong>Trim Video Start/End</strong>: Skips the parts where the warp is outside of the bounds of the original video. Warning: video must be longer than warp amount in seconds!</li>
                </ul>

                <p style={{textAlign:'center'}}>Video Time Warp's privacy policy can be found <Link to="/apps/video-time-warp/privacy-policy">here</Link>.</p>
            </div>
          );
    }
}

export default VideoTimeWarpHelp;