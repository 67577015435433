import React from 'react';
import { Routes, Route } from "react-router-dom";

import Home from './apps/Home';
import VideoTimeWarp from './apps/VideoTimeWarp';
import BodyBuildingBuddy from './apps/BodyBuildingBuddy';
import CrazyGoggles from './apps/CrazyGoggles';
import NotFound from '../NotFound';

class Apps extends React.Component {
    render() {
        return (
            <div>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home />} />
                        <Route path="/body-building-buddy" element={<BodyBuildingBuddy />} />
                        <Route path="/video-time-warp/*" element={<VideoTimeWarp />} />
                        <Route path="/crazy-goggles" element={<CrazyGoggles />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </div>
          );
    }
}

export default Apps;
