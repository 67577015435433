import React from 'react';
import { Routes, Route, Link} from "react-router-dom";
import TwoColumn from '../../TwoColumn';

class Home extends React.Component {
    render() {
        return (
            <div>
                <TwoColumn href="juice-galaxy" imgSrc="https://s5.gifyu.com/images/giant-monsters-skirmish_wrongpalette.gif" title="Juice Galaxy" text="Free physics-based ragdoll RPG in ongoing development."/><br/>
                <TwoColumn href="swordface" imgSrc="/res/Games/Swordface/swordface.gif" title="Swordface" text="Defend the earth from an onslaught of invaders. Upgrade yourself and hire mercenaries."/><br/>
                <TwoColumn href="https://fishlicka.itch.io/schwing" imgSrc="/res/Games/Schwing/schwingshot.png" title="Schwing" text="Do you swing the bat, or does the bat swing you? A ragdoll physics-based bat swinging atmospheric game."/><br/>
                <TwoColumn href="old-flash" imgSrc="/res/Games/OldFlash/shave.png" title="Old Flash Games" text="Some fun old game prototypes."/>
            </div>
          );
    }
}

export default Home;