import React from 'react';
import { Helmet } from 'react-helmet';

class FloppyFrank extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <script src="https://unpkg.com/@ruffle-rs/ruffle"></script>
                </Helmet>

                <div style={{textAlign:'center'}}>
                    <div><embed src="/res/Games/OldFlash/floppyfrank.swf" width="640" height="480"></embed></div>
                </div>
                
                <div style={{width:"640px",margin:"auto"}}>
                    <p style={{textAlign:'center'}}>Just click and drag, the mouse will grab onto the nearest foot or hand.</p>
                </div>
            </div>
          );
    }
}

export default FloppyFrank;