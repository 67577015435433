import React from 'react';
import { Helmet } from 'react-helmet';

class Swordface extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <script src="https://unpkg.com/@ruffle-rs/ruffle"></script>
                </Helmet>

                <div style={{textAlign:'center'}}>
                    <div><embed src="/res/Games/Swordface/SwordFace0.7Hotfix0.3.swf" width="640" height="480"></embed></div>
                    <div class="flexRow" style={{padding:'1em'}}>
                        <div class="flexCol"><a href="https://www.newgrounds.com/portal/view/733733">Play on Newgrounds.com</a></div>
                        <div class="flexCol"><a href="https://fishlicka.itch.io/schwing">Play on Itch.io</a></div>
                    </div>
                </div>
                
                <div>
                    <p>SwordFace is a difficult combat game where you flip around in space, slashing baddies with the sword attached to your head.</p>
                    <p>You gather coins to upgrade your stats such as health, regen, speed, turn rate, and there is even a stat that does nothing! The gripping story follows Swordface McGillicutty from day one at his new job defending the earth, all the way to the heat death of the universe.</p>

                    <p>There is a DevLog for those that are interested.<br/>
                    <a href="https://forums.tigsource.com/index.php?topic=66186.0">https://forums.tigsource.com/index.php?topic=66186.0</a></p>

                    <p>And also a fun trailer:<br/>
                    <a href="https://www.youtube.com/watch?v=D7yggNT2RZ4">https://www.youtube.com/watch?v=D7yggNT2RZ4</a></p>
                </div>
            </div>
          );
    }
}

export default Swordface;