import React from 'react';
import { Link } from 'react-router-dom';

class TwoColumn extends React.Component {
    constructor() {
        super();
        this.state = { width: window.innerWidth };
    }

    render() {
        let link, title, img = ( <img src={this.props.imgSrc} width="100%" height="auto" alt={this.props.imgAlt} style={{minHeight:'100px'}}></img> );
        if (this.props.href.startsWith('http')) {
            title = (<a href={this.props.href}><h2>{this.props.title}</h2></a>);
            link = (<a href={this.props.href}>{img}</a>);
        } else {
            title = (<Link to={this.props.href}><h2>{this.props.title}</h2></Link>);            
            link = (<Link to={this.props.href}>{img}</Link>);
        }

        if (window.innerWidth >= 640 && window.innerWidth >= window.innerHeight)
            return (
                <div className="flexRow">
                    <div className="flexCol" style={{position:"relative"}}>
                        <div style={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',marginRight:'5%'}}>
                            {title}
                            {this.props.text}
                        </div>
                    </div>
                    <div className="flexCol">
                        {link}
                    </div>
                </div>
            );
        
        return (
            <div>
                {title}
                {this.props.text}
                <div className="flexCol" style={{paddingTop:"8px"}}>
                    {link}
                </div>
            </div>
        );
    }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

}

export default TwoColumn;
