import React from 'react';

class NotFound extends React.Component {
    render() {
        return (
            <div>
                <h2 style={{textAlign:'center'}}>Wuh oh, page not found.</h2>
            </div>
          );
    }
}

export default NotFound;