import React from 'react';

class AboutThisSite extends React.Component {
    render() {
        return (
            <div>
                <h2>About This Site</h2>
                <strong>What is it?</strong>
                <p>This is my personal website, where I host a variety of projects including games, apps, and open source projects.</p>
                <strong>Who am I?</strong>
                <p>I am an indie software developer with roots in game development but interest in many areas. As of yet I've made a number of silly games and some interesting apps.</p>
                <p>I plan to continue developing interesting things and to provide them here for free.</p>
            </div>
          );
    }
}

export default AboutThisSite;