import React from 'react';
import { Routes, Route, Link} from "react-router-dom";
import TwoColumn from '../../../TwoColumn';

class Home extends React.Component {
    render() {
        return (
            <div>
                <h2>Ye Ould Flash Games</h2>
                <div>A selection of old flash games I made while learning to program between 2010 and 2013.</div>
                <p>The flash games hosted on this site are emulated with <a href="https://ruffle.rs/">Ruffle</a>, a rust-based flash player. For this reason, they may run a bit slowly.</p>
                <div className="spacer10M32"></div>
                <TwoColumn href="https://www.newgrounds.com/portal/view/561969" imgSrc="/res/Games/OldFlash/essay.png" 
                    title="The Essay" text={(<div>A speed typing game where you can either choose <cite>A Brother of St Francis</cite>, or copy in some text of your choice.
                        <p>It was frontpaged on Newgrounds and went viral in Finland.</p></div>)}/><br/>
                <TwoColumn href="https://www.newgrounds.com/portal/view/593377" imgSrc="/res/Games/OldFlash/pogo2.png" 
                    title="Pogo's Insanity Rush" text={(<div>A horizontal runner much like the popular <cite>Robot Unicorn</cite>.
                        <p>Except that as time goes on, Pogo the dinosaur starts to hallucinate more and more.</p></div>)}/><br/>
                <TwoColumn href="floppy-frank" imgSrc="/res/Games/OldFlash/floppy.png" 
                    title="Floppy Frank" text={(<div>Just toss this ragdoll guy around with the mouse.</div>)}/><br/>
                <TwoColumn href="shave-the-world" imgSrc="/res/Games/OldFlash/shave.png" 
                    title="Shave the World" text={(<div>A facial hair generator / face shaving game prototype.
                        <p>Hit 'R' to generate a new face.</p></div>)}/><br/>
                <TwoColumn href="purple-people-eater" imgSrc="/res/Games/OldFlash/purpleppleater.png" 
                    title="Purple People Eater" text={(<div>Experimental. Defend the planet from meteors using your long snakey tail.
                        <p>Press 'X' to grow your tail.</p></div>)}/>
            </div>
          );
    }
}

export default Home;