import React from 'react';
import { Link } from 'react-router-dom';

class Code extends React.Component {
    render() {
        return (
            <div>
                <h2>Open Source</h2>
                <ul>
                    <li><a href="https://github.com/Jackson-Rain/body-building-buddy">Body Building Buddy</a> - A simple React app that periodically nags you do random exercises. It is available online <Link to="/apps/body-building-buddy">here</Link>.</li>
                    <br />
                    <li><a href="https://github.com/Jackson-Rain/UnityScripts/tree/master/Ragdoll%20Puppetry">Unity Scripts and Ragdoll Puppetry</a> - The ragdoll puppetry system I used for <a href="https://fishlicka.itch.io/schwing">Schwing</a> and some general purpose scripts as well.</li>
                    <br />
                    <li><a href="https://github.com/Jackson-Rain/bytebase">Bytebase, a Minimal NodeJS Database</a> - A small script packaged into an <a href="https://www.npmjs.com/package/bytebase">NPM module</a> for basic tables without excessive dependencies.</li>
                </ul>
            </div>
          );
    }

    componentDidMount() {

    }
}

export default Code;
