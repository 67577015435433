import React from 'react';
import { Routes, Route, Link} from "react-router-dom";

import Home from './video-time-warp/Home';
import VideoTimeWarpHelp from './video-time-warp/VideoTimeWarpHelp';
import PrivacyPolicy from './video-time-warp/PrivacyPolicy';
import NotFound from '../../NotFound';

class Apps extends React.Component {
    render() {
        return (
            <Routes>
                {/* <Route exact path="/apps/video-time-warp" element={<Home />} />
                <Route path="/apps/video-time-warp/help" element={<VideoTimeWarpHelp />} />
                <Route path="/apps/video-time-warp/privacy-policy" element={<PrivacyPolicy />} />
                <Route element={<NotFound />} /> */}
                <Route path="/">
                    <Route index element={<Home />} />
                    <Route path="/help" element={<VideoTimeWarpHelp />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
          );
    }
}

export default Apps;
