import React from 'react';
import Contact from './Contact'

class Donate extends React.Component {
    render() {
        return (
            <div style={{textAlign:'center'}}>
                <Contact />
                <p>For now, donations aren't implemented. But you can donate kind words!</p>
            </div>
          );
    }

    componentDidMount() {
        
    }
}

export default Donate;