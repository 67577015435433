import React from 'react';

class StockPriceHeatmaps extends React.Component {
    render() {
        return (
            <div style={{maxWidth:'720px', margin:'auto'}}>
                <h2>Stock Price Time-Series Heatmaps</h2>
                <p>I wrote some software to make heatmaps of stock/crypto price movements in order to gain understanding and test patterns. The software runs on linux shell, NodeJS, and plain C.</p>

                <img style={{width:'100%',height:'auto'}} src="https://jacksonrain.files.wordpress.com/2018/12/MSFT_all.jpg" alt="MSFT_all" width="640" height="480" />

                <p>A heatmap of average Microsoft stock price change over ten week period, drawn on a logarithmic scale. Each column is a probability mass function of the change in price since the beginning of the time series.</p>

                <h4>Data Acquisition and Handling</h4>
                <p>NodeJS is used to download daily stock price JSON data from the AlphaVantage API. The data is then condensed and saved using <a href="https://github.com/Jackson-Rain/ByteBase">ByteBase</a>, my own binary table database written in NodeJS. Care is taken to adjust price data for stock splits.</p>
                <h4>Signal Definition</h4>
                <p>The system takes a signal: a set of points in time with varying signal strength.</p>

                <p>A signal can be anything, as simple as a list of Monday mornings or as complex as the number of stock-related Twitter posts mentioning waffles during each hour. Signals are stored in ByteBase tables of [time(long),strength(float)].</p>

                <p>Some signals I've used include:</p>
                <ul>
                    <li>Constant signal weight of 1.0</li>
                    <li>Daily trade volume</li>
                    <li>Mondays</li>
                    <li>Christmas and other significant days</li>
                    <li>Stock Splits</li>
                    <li>Momentum of a related stock's price movement</li>
                </ul>
                <h4>Heatmap Generation</h4>
                <p>At each point where the signal strength > 0, the ensuing price action is imprinted onto the heatmap. The sequence is normalized by the price at the time of the signal, so that it is the relative price movement that is mapped rather than the price itself. There is an example sequence below:</p>
                <table style={{margin:'auto'}}>
                    <tbody>
                        <tr>
                            <td>Actual Price</td>
                            <td>134.25</td>
                            <td>136.45</td>
                            <td>131.16</td>
                            <td>132.03</td>
                            <td>138.65</td>
                            <td>137.75</td>
                        </tr>
                        <tr>
                            <td>Price Change</td>
                            <td>1</td>
                            <td>1.016</td>
                            <td>0.967</td>
                            <td>0.983</td>
                            <td>1.038</td>
                            <td>1.026</td>
                        </tr>
                    </tbody>
                </table>

                <p>The normalized price action is imprinted onto the heatmap with a weight equal to the signal strength. After all imprints are made, the map is normalized by the total weight of the imprints.</p>

                <p>The map is generated by a C executable that reads from ByteBase tables. The relatively fast execution enables the developer to test a bunch of signals quickly.</p>

                <h4>Prediction</h4>
                <p>Signal generators can also be used to calculate the signal in the present. If the signal is strong, the ensuing price action is likely to be consistent with the the signal's calculated heatmap. A single prediction heatmap can be generated from multiple different signals' heatmaps using present signal values as weights.</p>

                <h4>Visualization</h4>
                <p>Heatmaps are saved in a binary format. To view them, a NodeJS script is used to render an image from the heatmap. The heatmap is drawn on a logarithmic scale, so that the deltas bound between 0-1, and those bound between 1-infinity can be viewed symmetrically. By altering visMap.js, you can experiment with the map coloring function to produce more attractive or meaningful images. Additionally, animated GIFs can be generated using ffmpeg.</p>

                <h4>Usage</h4>
                <p>The whole point is that signal generators can quickly be written to explore theories. What does a stock do over election cycles? Is there a pattern to a stock's intra-day or intra-week price activity? How does one stock price depend on another's? How does an agricultural company's performance depend on the temperature in the growing season?</p>
                <p>Of course, the application is not limited to stock prices. Any time-series data will do.</p>
                <p>In the future, I hope to expand this project's functionality, possibly providing an API to the public. If there is interest, I can clean up and share the git repo. I'd like to see what others come up with! It would be amazing to have some sort of community hub where signal generators are shared and discussed.</p>
            </div>
          );
    }
}

export default StockPriceHeatmaps;