import React from 'react';

class CrazyGoggles extends React.Component {
    render() {
        return (
            <div>
                <h2>Crazy Goggles</h2>
                <p>Upcoming app that lets you play with some crazy filters. The app can take pictures, capture/edit videos, and also be used as a virtual reality toy if want to strap it to your face (i.e. Google Cardboard) and walk around outside like a crazy person.</p>
                <p>Below are some examples of the nifty views that can be produced.</p>
                <div><img src="/res/Apps/crazy-goggles/2019-06-16-16-20-05.png" style={{maxWidth:"100%"}}></img></div>
                <div><img src="/res/Apps/crazy-goggles/2019-06-16-16-24-01.png" style={{maxWidth:"100%"}}></img></div>
                <div><img src="/res/Apps/crazy-goggles/2019-08-01-19-02-05.png" style={{maxWidth:"100%"}}></img></div>
                <div><img src="/res/Apps/crazy-goggles/2019-08-01-19-04-55.png" style={{maxWidth:"100%"}}></img></div>
                <div><img src="/res/Apps/crazy-goggles/2019-08-25-21-34-28.png" style={{maxWidth:"100%"}}></img></div>
                <div><img src="/res/Apps/crazy-goggles/2019-08-25-21-35-31.png" style={{maxWidth:"100%"}}></img></div>
            </div>
          );
    }
}

export default CrazyGoggles;
