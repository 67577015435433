import React from 'react';
import './App.css';
import './Extra.css';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from './comp/Header';
import Footer from './comp/Footer';
import NotFound from './comp/NotFound';

import Home from './comp/page/Home';
import Code from './comp/page/Code';
import Other from './comp/page/Other';
import Games from './comp/page/Games';
import Apps from './comp/page/Apps';
import Contact from './comp/page/Contact';
import Donate from './comp/page/Donate';
import OldFlash from './comp/page/games/OldFlash';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        {/* <Navbar /> */}
        <div className="page">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/code" element={<Code />} />
            <Route path="/games/*" element={<Games />} />
            <Route path="/games/old-flash/*" element={<OldFlash />} />
            <Route path="/apps/*" element={<Apps />} />
            <Route path="/other/*" element={<Other />} />
            <Route path="/contact" element={<Contact />} />
            <Route exact path="/donate" element={<Donate />} />
            <Route element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;