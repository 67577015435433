import React from 'react';
import { Routes, Route, Link} from "react-router-dom";
import TwoColumn from '../../TwoColumn';

class Home extends React.Component {
    render() {
        return (
            <div>
                <TwoColumn href="body-building-buddy" imgSrc="/res/Apps/body-building-buddy.png" title="Body Building Buddy" text={(
                    <div><p>A random exercise generator that periodically nags you to get up and exercise, good for desk potatoes.</p></div>
                )}/><br/>
                <TwoColumn href="video-time-warp" imgSrc="/res/Apps/video-time-warp_75p.png" title="Video Time Warp" text="An android app for creating time-displaced video."/><br/>
                <TwoColumn href="crazy-goggles" imgSrc="/res/Apps/crazy-goggles.png" title="Crazy Goggles" text="Coming soon, a set of seriously crazy video camera filters with virtual reality capabilities."/>
            </div>
          );
    }
}

export default Home;