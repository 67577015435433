import React from 'react';
import { Helmet } from 'react-helmet';

class PurplePeopleEater extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <script src="https://unpkg.com/@ruffle-rs/ruffle"></script>
                </Helmet>

                <div style={{textAlign:'center'}}>
                    <div><embed src="/res/Games/OldFlash/purplepeopleeater.swf" width="640" height="480"></embed></div>
                </div>
                
                <div style={{width:"640px",margin:"auto"}}>
                    <p>Deflect asteroids using your tail without getting your monkey head hit. <br/> Press X to grow your tail.</p>
                </div>
            </div>
          );
    }
}

export default PurplePeopleEater;