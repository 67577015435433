import React from 'react';
import { Link } from 'react-router-dom';
import TwoColumn from '../TwoColumn';

function Home() {
  return (
    <div>
        <h2>Welcome</h2>
        <p>Hello! I'm an indie game and software developer who specializes in crafting unique and experimental experiences. This website is dedicated to showcasing some of my projects.</p>
        <p>Use the navbar above, or check out some of my favorites:</p>
        <div className='spacer10M32' />
        <TwoColumn href="games/juice-galaxy" imgSrc="https://s5.gifyu.com/images/giant-monsters-skirmish_wrongpalette.gif" title="Juice Galaxy" text="Free physics-based ragdoll RPG in ongoing development."/><br/>
        <TwoColumn href="games/swordface" imgSrc="/res/Games/Swordface/swordface.gif" title="Swordface" text="Defend the earth from an onslaught of invaders. Upgrade yourself and hire mercenaries."/><br/>
        <TwoColumn href="apps/body-building-buddy" imgSrc="/res/Apps/body-building-buddy.png" title="Body Building Buddy" text={(
                    <div><p>A random exercise generator that periodically nags you to get up and exercise, good for desk potatoes.</p></div>
                )}/><br/>
        <TwoColumn href="apps/video-time-warp" imgSrc="/res/Apps/video-time-warp_75p.png" title="Video Time Warp" text="An android app for creating time-displaced video."/><br/>
    </div>
  );
}

export default Home;